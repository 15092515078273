import { createSlice, current } from '@reduxjs/toolkit'
import { getCookie } from '../../utils/security/cookies'
import { ConfigurationState } from './constants'

import {
  requestGetConfigInfosStates,
  requestIdRulesStates,
  requestPatchConfigInfosStates,
  requestSetIdRulesStates,
  requestSetVoteInfosStates,
  requestVoteInfosStates,
  requestCheckThirdPartyAvailableStates,
} from './services'

// Define the initial state using that type
export const initialState: ConfigurationState = {
  // type: '--', // ici type
  type: getCookie('TYPE'),
  thirdPartyAvailable: false,
  config: undefined,
  configTmp: undefined,
  voteTmp: {},
  configByEstablishement: [],
  selectedEstablishment: undefined,
  isError: false,
  idRules: {},
}

export const configuration = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    SetConfigTmp: (state, { payload: value }) => {
      state.configTmp ||= {}
      state.configTmp[value.key] = value.value
    },

    ResetConfigTmp: (state) => {
      state.configTmp = { ...state.config }
    },

    SetVoteTmp: (state, { payload }) => {
      state.voteTmp = payload.config
    },
    ResetVoteTmp: (state) => {
      state.voteTmp = initialState.voteTmp
      state.selectedEstablishment = initialState.selectedEstablishment
      state.isError = initialState.isError
    },
    SetSelectedEstablishment: (state, { payload: newId }) => {
      if (newId) {
        state.selectedEstablishment = {
          ...state.configByEstablishement?.find(({ id }) => newId === id),
          ...state.voteTmp[newId],
        }
      } else {
        state.selectedEstablishment = undefined
        state.isError = false
      }
    },
    SetEstablishmentNotCSE: (state) => {
      state.selectedEstablishment = {
        ...state.config,
        ...state.selectedEstablishment,
        ...state.configTmp,
      }
    },
    SetIsError: (state, { payload: status }) => {
      state.isError = status
    },
    SetVoteTmpById: (state, { payload }) => {
      state.voteTmp[payload.id] = payload.config
      state.selectedEstablishment = {
        ...(state.configByEstablishement?.find(({ id }) => id === payload.id) || { id: 'all' }),
        ...payload.config,
      }
    },
    ResetAllConfig: (state) => {
      state.config = initialState.config
      state.configTmp = initialState.configTmp
      state.voteTmp = initialState.voteTmp
      state.configByEstablishement = initialState.configByEstablishement
      state.selectedEstablishment = initialState.selectedEstablishment
      state.isError = initialState.isError
    },

    UpdateClosingTime: (state, { payload: currentDate }) => {
      if (
        (state.config?.isProd && state.config?.closingDate) ||
        state.config?.closingDateSimulation
      ) {
        const loggedTime = getCookie('logged_at')

        const data = state.config

        // logging time is not needed because we are now blocking-logging user in additional

        if (state.config.isProd) {
          let newClosing = new Date(
            new Date(data.closingDate).setMinutes(
              new Date(data.closingDate).getMinutes() + data.additionalVoteTime
            )
          )

          // if (
          //   loggedTime &&
          //   Number(new Date(loggedTime)) < Number(new Date(data.closingDate)) &&
          //   Number(currentDate) > Number(new Date(data.closingDate))
          // ) {
          //   newClosing.setMinutes(newClosing.getMinutes() + data.additionalVoteTime)
          // }

          data.closingTime = newClosing.toISOString()
        } else {
          let newClosing = new Date(
            new Date(data.closingDateSimulation).setMinutes(
              new Date(data.closingDateSimulation).getMinutes() + data.additionalVoteTime
            )
          )

          // if (
          //   loggedTime &&
          //   Number(new Date(loggedTime)) < Number(new Date(data.closingDateSimulation)) &&
          //   Number(currentDate) > Number(new Date(data.closingDateSimulation))
          // ) {
          //   newClosing.setMinutes(newClosing.getMinutes() + data.additionalVoteTime)
          // }

          data.closingTime = newClosing.toISOString()
        }

        state.config = data
      }
    },
  },
  extraReducers: (builder) => {
    requestGetConfigInfosStates(builder)
    requestPatchConfigInfosStates(builder)
    requestVoteInfosStates(builder)
    requestSetVoteInfosStates(builder)
    requestIdRulesStates(builder)
    requestSetIdRulesStates(builder)
    requestCheckThirdPartyAvailableStates(builder)
  },
})

export const {
  SetConfigTmp,
  ResetConfigTmp,
  SetVoteTmp,
  ResetVoteTmp,
  SetSelectedEstablishment,
  SetEstablishmentNotCSE,
  SetIsError,
  SetVoteTmpById,
  ResetAllConfig,
  UpdateClosingTime,
} = configuration.actions
